<template>
  <div class="car-wrapper" :class="{'bg-animation': run===2, 'animation-reverse': dir===2}">
    <div class="car">
      <div class="car-door" :class="(door===1||door===3)?'door-open':'door-close'">
      </div>
      <div class="floor">
        <img v-if="dir===2" :src="require('@/assets/img/monitor/lift/arrow_up.'+(run===2?'gif':'png'))"/>
        <img v-if="dir===3" :src="require('@/assets/img/monitor/lift/arrow_down.'+(run===2?'gif':'png'))"/>
        <span class="text">{{floor}}</span>
      </div>
    </div>
    <div v-if="connectTip" class="car-tip">
      <img class="car-loading" src="~@/assets/img/monitor/loading.gif"/>
      <span>{{connectTip}}</span>
    </div>
  </div>
</template>
<script>

  export default {
    components: {},
    props: {
      connectTip: {
        type: String,
      },
      run: {
        type: Number,
      },
      floor: {
        type: [Number, String],
      },
      dir: {
        type: Number,
      },
      door: {
        type: Number,
      },
    },
    data() {
      return {};
    },
    mounted() {
    },
    methods: {},
  };
</script>
<style lang="scss" scoped>
.car-wrapper {
  width: 440px;
  height: 583px;
  background-image: url(~@/assets/img/monitor/lift/bg.jpg);
  background-size: cover;
  position: relative;
}

.car {
  transform: scale(0.8, 0.8);
  width: 438px;
  height: 556px;
  background-image: url(~@/assets/img/monitor/lift/car.png);
  background-size: cover;
}

.car-tip {
  position: absolute;
  width: 100%;
  left: 0;
  top: 230px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  .car-loading {
    width: 60px;
    height: 60px;
    background-color: rgba(164, 243, 145, 0.1);
    padding: 5px;
    border: 1px solid white;
    border-radius: 50%;
  }

  span {
    color: white;
    font-size: 25px;
    margin-left: 5px;
    text-shadow: 2px 2px 4px #000000;
  }
}

.floor {
  transform: rotate(2deg);
  width: 20px;
  justify-content: center;
  color: red;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 139px;
  left: 145px;
  display: flex;

  .text {
    margin-left: 5px;
  }
}

.car-door {
  width: 100%;
  height: 100%;
  background: url(~@/assets/img/monitor/lift/door_status.png) no-repeat 0 0;
}

.door-open {
  animation: door-open-anim 1s steps(8) forwards;
}

.door-close {
  animation: door-close-anim 1s steps(8) forwards;
}

@keyframes door-open-anim {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -3504px;
  }
}

@keyframes door-close-anim {
  from {
    background-position-x: -3504px;
  }
  to {
    background-position-x: 0;
  }
}

.bg-animation {
  animation: animatedBackgroundDown 6s linear infinite;
  -moz-animation: animatedBackgroundDown 6s linear infinite;
}

.animation-reverse {
  animation-direction: reverse !important;
}

@keyframes animatedBackgroundDown {
  from {
    background-position-y: 0;
  }
  to {
    background-position-y: -1196px;
  }
}
</style>
